import React from "react";
import { Box, Container, Typography, Button, Grid, Card, CardContent } from '@mui/material';
import Section3Background from '../../../assets/images/Section3Background.png.png';
import Compliant from '../../../assets/icons/compliant.png';
import Empower from '../../../assets/icons/empower.png';
import Reporting from '../../../assets/icons/reporting.png';
import { orange } from '@mui/material/colors';

function Index() {
    return(

        <Box sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundImage: `url(${Section3Background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            py: { xs: 6, md: 8 }, // Adjust vertical padding for mobile and desktop
            px: { xs: 2, md: 4 }, // Adjust horizontal padding for mobile and desktop
            textAlign: 'center',
        }}>
            {/* Grid container for cards */}
            <Grid
                container
                spacing={{ xs: 2, sm: 4, md: 6 }} // Adjust spacing for different screen sizes
                justifyContent="center"
                sx={{paddingLeft: { xs: '20px', md: '100px' }, paddingRight: { xs: '20px', md: '100px' }}}

            >
                {/* Card 1 */}
                <Grid item xs={12} sm={6} md={4}>
                    <Card
                        sx={{
                            borderRadius: 4, // Rounded corners
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            height: '200px', // Ensure equal card height
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CardContent>
                            <img src={Reporting}/>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
                                Reporting Made Easy
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ mt: 1, color: 'text.secondary', textAlign: 'center' }}
                            >
                                Generate insightful reports to drive data-based decisions.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Card 2 */}
                <Grid item xs={12} sm={6} md={4}>
                    <Card
                        sx={{
                            borderRadius: 4,
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            height: '100%', // Ensure equal card height
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CardContent>
                            <img src={Compliant}/>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
                                Stay Compliant with Ease
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ mt: 1, color: 'text.secondary', textAlign: 'center' }}
                            >
                                Effortlessly manage HR compliance requirements.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Card 3 */}
                <Grid item xs={12} sm={6} md={4}>
                    <Card
                        sx={{
                            borderRadius: 4,
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            height: '100%', // Ensure equal card height
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CardContent>
                            <img src={Empower}/>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
                                Empower Your Employees
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ mt: 1, color: 'text.secondary', textAlign: 'center' }}
                            >
                                Enable self-service access for your workforce.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Button Section */}
            <Box
                sx={{
                    mt: { xs: 4, md: 6 }, // Adjust margin-top for mobile and desktop
                    display: 'flex',
                    flexDirection: { xs: 'row', sm: 'row' }, // Stack buttons on mobile, inline on larger screens
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2, // Add spacing between buttons
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        mr: 2,
                        backgroundColor: 'transparent',
                        textTransform: 'none',
                        borderRadius: '25px',
                        padding: '10px 45px',
                        border: '2px solid white',
                        color: '#fff'
                    }}
                >
                    Sign Up
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                        backgroundColor: '#393939',
                        color: '#fff',
                        textTransform: 'none',
                        borderRadius: '25px',
                        padding: '10px 35px',
                    }}
                >
                    Learn More
                </Button>
            </Box>
        </Box>
    )
}

export default Index;