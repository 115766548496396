import React from "react";
import { Box, Container, Typography, Button, Grid } from '@mui/material';
import Section5Background from '../../../assets/images/Section5Background.png';
import Efficient from '../../../assets/icons/efficient.png';
import Accurate from '../../../assets/icons/accurate.png';
import PhoneSalary from '../../../assets/images/phonesalary.png'
import Section6Background from "../../../assets/images/Section6Background.png";

function Index() {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                backgroundImage: `url(${Section5Background})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                padding: { xs: "20px", md: "50px" }, // Add responsive padding for mobile and desktop
                marginBottom: {xs: '100px', sm: '100px', md: '0px'},
                marginTop: {xs: '140px', sm: '70px', md: '0px'}
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={0} alignItems="center">

                    {/* Left Section: Content */}
                    <Grid item xs={12} md={6} paddingLeft={{xs: 0, md: 15}}>
                        <Box
                            sx={{
                                maxWidth: "400px",
                                textAlign: { xs: "center", md: "left" },
                                mx: { xs: "auto", md: 0 }, // Center content horizontally on mobile
                            }}
                        >
                            <Typography
                                variant="subtitle2"
                                fontWeight="bold"
                                sx={{
                                    color: "#F28C33",
                                    fontSize: { xs: "0.8rem", md: "1rem" },
                                    textAlign: { xs: "center", md: "left" },
                                }}
                                gutterBottom
                            >
                                SIMPLIFY
                            </Typography>
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: "bold",
                                    color: "#000",
                                    fontSize: { xs: "1.4rem", md: "2rem" },
                                    textAlign: { xs: "center", md: "left" },
                                }}
                                gutterBottom
                            >
                                Streamline Payroll Processing for Accurate Results
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: "#555",
                                    fontSize: { xs: "0.9rem", md: "1rem" },
                                    lineHeight: 1.6,
                                    marginBottom: { xs: 2, md: 4 },
                                    textAlign: { xs: "center", md: "left" },
                                }}
                            >
                                Our payroll features simplifies the payroll process,ensuring
                                accuracy and saving you time. With IzzyHR.com, you can
                                easily manage employee salaries, deductions and tax
                                calculations.
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },
                                    gap: 2,
                                    justifyContent: { xs: "center", md: "flex-start" },
                                }}
                            >
                                <Grid item xs={4} md={8}>

                                </Grid>
                                <Grid item xs={4} md={8}>

                                </Grid>
                            </Box>
                            <Box sx={{ marginBottom: 3 }}>
                                <Box sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    gap: 2,
                                    marginBottom: 2,
                                    textAlign: 'left'
                                }}>
                                    <img  src={Efficient} alt="Efficient Payroll" />
                                    <Typography variant="body1" sx={{ color: "#555", lineHeight: 1.6 }}>
                                        <strong>Efficient Payroll</strong>
                                        <br />
                                        Automate payroll calculations and ensure accurate salary payments for your employees.
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    gap: 2,
                                    textAlign: 'left'
                                }}>
                                    <img src={Accurate} alt="Accurate Reports" />
                                    <Typography variant="body1" sx={{ color: "#555", lineHeight: 1.6 }}>
                                        <strong>Accurate Reports</strong>
                                        <br />
                                        Generate detailed reports on payroll expenses, tax deductions, and employee earnings.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    {/* Right Section: Empty space */}
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundImage: `url(${Section6Background})`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                            }}
                        >
                            <Box
                                component="img"
                                src={PhoneSalary}
                                alt="Phone"
                                sx={{
                                    width: { xs: "70%", sm: "70%", md: "80%" },
                                    maxWidth: { xs: "300px", sm: "400px", md: "500px" }, // Responsive sizes
                                    height: "auto",
                                    display: "block",
                                    margin: "0 auto", // Center for mobile
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>

            </Container>
        </Box>
    );
}

export default Index;
