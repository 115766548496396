import React from "react";
import { Box, Container, Typography, Button, Grid } from '@mui/material';
import SampleLocation from "../../../assets/images/SampleLocation.png";
import Efficient from "../../../assets/icons/efficient.png";
import Accurate from "../../../assets/icons/accurate.png";
import Envelop from "../../../assets/icons/envelop.png";
import Phone from "../../../assets/icons/phone.png";
import location from "../../../assets/icons/location.png";

function Index() {
    return(
        <Box>
            <Box
                sx={{
                    padding: { xs: '20px', sm: '40px 60px', md: '50px 100px', lg: '50px 200px' }, // Responsive padding
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' }, // Stack items on small screens, align side-by-side on larger screens
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: { xs: 4, md: 0 }, // Add spacing between items on small screens

                }}
            >
                {/* Left Section */}
                <Box
                    sx={{
                        paddingRight: { xs: 0, md: 20 }, // Adjust padding on small screens
                        textAlign: { xs: 'center', md: 'left' }, // Center text on small screens
                        width: {md: '1000px'}
                    }}
                >
                    <Typography
                        variant="subtitle2"
                        fontWeight="bold"
                        color="primary"
                        gutterBottom
                    >
                        CONNECTING
                    </Typography>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 'bold',
                            marginBottom: 2,
                            fontSize: { xs: "1rem", md: "1.8rem" }
                        }}
                    >
                        Get in Touch
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: { xs: 2, md: 4 } }}>
                        We would love to hear from you. Contact us for any inquiries or feedback.
                    </Typography>
                </Box>

                {/* Right Section */}
                <Box
                    sx={{
                        paddingLeft: { xs: 0, md: 0 }, // Remove padding on small screens
                        textAlign: { xs: 'center', md: 'left' }, // Center text on small screens
                    }}
                >
                    {/*<Box sx={{ marginBottom: 3 }}>*/}
                    <Box sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 2,
                        marginBottom: 2,
                        textAlign: 'left'
                    }}>
                        <img  src={Envelop} alt="Envelop" width={'25'}/>
                        <Typography variant="body1" sx={{ color: "#555", lineHeight: 1.6 }}>
                            <strong>Email</strong>
                            <br />
                            admin@octaneware.com
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 2,
                        textAlign: 'left',
                        marginBottom: 2
                    }}>
                        <img src={Phone} alt="Phone" width={'25'}/>
                        <Typography variant="body1" sx={{ color: "#555", lineHeight: 1.6 }}>
                            <strong>Phone</strong>
                            <br />
                            +639 123 456 789
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 2,
                        textAlign: 'left'
                    }}>
                        <img src={location} alt="Phone" width={'25'}/>
                        <Typography variant="body1" sx={{ color: "#555", lineHeight: 1.6 }}>
                            <strong>Location</strong>
                            <br />
                            11F Park Centrale Building, Jose Maria Del Mar St. Cebu IT Park, Lahug, Cebu City, Cebu
                        </Typography>
                    </Box>
                    {/*</Box>*/}
                </Box>


            </Box>
            <Box
                sx={{padding: { xs: '20px', sm: '40px 60px', md: '50px 100px', lg: '50px 200px' }}}
            >
                <Box
                    component="img"
                    src={SampleLocation}
                    alt="Location"
                    sx={{
                        width: { xs: "100%", sm: "100%", md: "100%" },
                        // maxWidth: { xs: "300px", sm: "400px", md: "500px" }, // Responsive sizes
                        height: "auto",
                        display: "block",
                        margin: "0 auto", // Center for mobile
                    }}
                />
            </Box>
        </Box>

    )
}

export default Index;