import React, {useEffect, useState} from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import Laptop from "../../../assets/images/laptop.png";
import './style.css';

function Index() {

    const phrases = [
        "Transform your HR Processes",
        "Automate your work",
        "Streamline your processes",
        "Compute your payroll",
        "Achieve more, effortlessly"
    ];


    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
    const [displayedText, setDisplayedText] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    const [isPaused, setIsPaused] = useState(false);

    // Speed configurations
    const typingSpeed = 130; // Slower typing: 300ms per character
    const deletingSpeed = 50; // Faster deleting: 150ms per character
    const pauseDuration = 1000; // Pause duration after fully typing

    useEffect(() => {
        if (isPaused) return; // Skip further updates during pauses

        const currentPhrase = phrases[currentPhraseIndex];

        if (isDeleting) {
            // Deleting process
            if (displayedText.length > 0) {
                const timer = setTimeout(() => {
                    setDisplayedText((prev) => prev.slice(0, prev.length - 1));
                }, deletingSpeed);
                return () => clearTimeout(timer);
            } else {
                // Fully deleted, move to the next phrase
                setIsDeleting(false);
                setCurrentPhraseIndex((prev) => (prev + 1) % phrases.length);
                setIsPaused(true);
                setTimeout(() => setIsPaused(false), 500); // Pause before starting to type
            }
        } else {
            // Typing process
            if (displayedText.length < currentPhrase.length) {
                const timer = setTimeout(() => {
                    setDisplayedText((prev) => currentPhrase.slice(0, prev.length + 1));
                }, typingSpeed);
                return () => clearTimeout(timer);
            } else {
                // Fully typed, pause before starting to delete
                setIsPaused(true);
                setTimeout(() => {
                    setIsDeleting(true);
                    setIsPaused(false);
                }, pauseDuration);
            }
        }
    }, [displayedText, isDeleting, isPaused, currentPhraseIndex, phrases, typingSpeed, deletingSpeed]);


    return (
        <>
            <Container
                sx={{
                    position: 'relative', // Allows for flexible positioning of child elements
                    height: { xs: '100vh', md: '100vh' }, // Full viewport height on large screens, auto on smaller screens
                    display: 'flex', // Enables flexbox alignment
                    flexDirection: 'column', // Stack content vertically on small screens
                    alignItems: 'center', // Center content horizontally
                    // justifyContent: { xs: 'flex-start', md: 'center' }, // Top-align on small screens, center on larger screens
                    // padding: { xs: '20px', sm: '40px', md: '0' }, // Responsive padding
                    // marginTop: { xs: '-200px', md: '-250px' }, // Adjust position responsively
                    marginTop: {xs: '120px', md: '120px'}
                }}
                maxWidth="lg"
            >
                <Box
                    className="hero-section"
                    textAlign="center"
                    sx={{
                        backgroundColor: 'transparent', // No background to maintain original design
                        // padding: { xs: '10px 20px', md: '20px 40px' }, // Responsive padding
                        width: '100%', // Ensures the box scales to fit the screen width
                        // marginTop: {xs: '-650px', sm: '-650px', md: '-450px'}
                    }}
                >
                    <Typography
                        variant="h5"
                        gutterBottom
                        sx={{
                            fontSize: { xs: '1.4rem', sm: '1.8rem', md: '2.3rem' }, // Adjust font size for responsiveness
                            fontWeight: 'bold',
                        }}
                    >
                        {displayedText}
                        <span className={"cursor"} style={{ color: "gray" }}>|</span> {/* Cursor */}
                        <br /> with IzzyHR.com
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        paragraph
                        sx={{
                            mt: 2,
                            mb: 2,
                            fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }, // Responsive font size
                        }}
                    >
                        Streamline your HR tasks and improve efficiency with our comprehensive HR solutions.
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'row', sm: 'row' }, // Stack buttons on mobile
                            gap: 2, // Space between buttons
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                backgroundColor: '#F28C33',
                                textTransform: 'none',
                                borderRadius: '25px',
                                padding: { xs: '10px 30px', md: '10px 50px' },
                                fontSize: { xs: '0.7rem', sm: '1rem' }, // Adjust font size for buttons
                                color: '#fff',
                            }}
                        >
                            Explore
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{
                                backgroundColor: '#393939',
                                color: '#fff',
                                textTransform: 'none',
                                borderRadius: '25px',
                                padding: { xs: '10px 20px', md: '10px 35px' },
                                fontSize: { xs: '0.7rem', sm: '1rem' }, // Adjust font size for buttons
                            }}
                        >
                            Learn More
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ marginTop: '20px'}}>
                    <img
                        src={Laptop}
                        alt="Computer"
                        style={{
                            width: "100%",
                            maxWidth: "500px", // Adjust the max width for larger screens
                            height: "auto", // Maintain aspect ratio
                        }}
                    />
                </Box>
            </Container>
        </>
    );
}

export default Index;
