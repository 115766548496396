import React from "react";
import { Box, Grid, Typography, Avatar, IconButton } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";

const teamMembers = [
    {
        name: "John Doe",
        position: "CEO",
        description: "John Doe is an experienced HR professional with a strong background in technology.",
        img: "", // Replace with the image URL
        socials: {
            instagram: "#",
            linkedin: "#",
            twitter: "#",
        },
    },
    {
        name: "Jane Smith",
        position: "CTO",
        description: "Jane Smith is a technology expert with a deep understanding of HR systems.",
        img: "", // Replace with the image URL
        socials: {
            instagram: "#",
            linkedin: "#",
            twitter: "#",
        },
    },
    {
        name: "Sarah Johnson",
        position: "HR Manager",
        description: "Sarah Johnson is a skilled HR professional with a passion for employee well-being.",
        img: "", // Replace with the image URL
        socials: {
            instagram: "#",
            linkedin: "#",
            twitter: "#",
        },
    },
];

function Index() {
    return (
        <Box
            sx={{
                padding: { xs: "40px 20px", md: "80px 40px" },
                textAlign: "center",
            }}
        >
            {/* Section Header */}
            <Typography
                variant="subtitle2"
                sx={{
                    color: "#FF6B01",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    marginBottom: "8px",
                }}
            >
                Empowering HR
            </Typography>
            <Typography
                variant="h5"
                sx={{
                    fontWeight: "bold",
                    marginBottom: "16px",
                    fontSize: { xs: "1rem", md: "1.8rem" }
                }}
            >
                Meet Our Team
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    color: "#555",
                    marginBottom: "40px",
                }}
            >
                Our leadership team and key members bring HR and technology expertise.
            </Typography>

            {/* Team Grid */}
            <Grid container spacing={4} justifyContent="center">
                {teamMembers.map((member, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box
                            sx={{
                                textAlign: "center",
                                padding: "20px",
                                border: "1px solid #eee",
                                borderRadius: "8px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            {/* Profile Picture */}
                            <Avatar
                                alt={member.name}
                                src={member.img || "https://via.placeholder.com/150"} // Use placeholder if no image
                                sx={{
                                    width: "150px",
                                    height: "150px",
                                    margin: "0 auto",
                                    marginBottom: "16px",
                                    background: "#f5f5f5",
                                }}
                            />

                            {/* Name and Position */}
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: "bold",
                                    marginBottom: "8px",
                                }}
                            >
                                {member.name}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontWeight: "medium",
                                    color: "#FF6B01",
                                    marginBottom: "8px",
                                }}
                            >
                                {member.position}
                            </Typography>

                            {/* Description */}
                            <Typography
                                variant="body2"
                                sx={{
                                    color: "#777",
                                    marginBottom: "16px",
                                }}
                            >
                                {member.description}
                            </Typography>

                            {/* Social Media Icons */}
                            <Box sx={{ display: "flex", justifyContent: "center", gap: "16px" }}>
                                <IconButton
                                    href={member.socials.instagram}
                                    target="_blank"
                                    rel="noopener"
                                    aria-label="Instagram"
                                    color="primary"
                                >
                                    <InstagramIcon />
                                </IconButton>
                                <IconButton
                                    href={member.socials.linkedin}
                                    target="_blank"
                                    rel="noopener"
                                    aria-label="LinkedIn"
                                    color="primary"
                                >
                                    <LinkedInIcon />
                                </IconButton>
                                <IconButton
                                    href={member.socials.twitter}
                                    target="_blank"
                                    rel="noopener"
                                    aria-label="Twitter"
                                    color="primary"
                                >
                                    <FacebookIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default Index;
