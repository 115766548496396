import React from "react";
import { Box, Container, Typography, Button, Grid } from '@mui/material';

function Index() {
    return(

        <Box
            sx={{
                padding: { xs: '20px', sm: '40px 60px', md: '50px 100px', lg: '50px 200px' }, // Responsive padding
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' }, // Stack items on small screens, align side-by-side on larger screens
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: { xs: 4, md: 0 }, // Add spacing between items on small screens

            }}
        >
            {/* Left Section */}
            <Box
                sx={{
                    borderRight: { xs: 'none', md: '2px solid #CECECE' }, // Remove border on small screens
                    paddingRight: { xs: 0, md: 10 }, // Adjust padding on small screens
                    textAlign: { xs: 'center', md: 'left' }, // Center text on small screens
                }}
            >
                <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="primary"
                    gutterBottom
                >
                    EFFICIENCY
                </Typography>
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: 2,
                        fontSize: { xs: "1rem", md: "1.8rem" }
                    }}
                >
                    Unlock the Power of IzzyHR.com for Your Business
                </Typography>
            </Box>

            {/* Right Section */}
            <Box
                sx={{
                    paddingLeft: { xs: 0, md: 15 }, // Remove padding on small screens
                    textAlign: { xs: 'center', md: 'left' }, // Center text on small screens

                }}
            >
                <Typography variant="body1" sx={{fontSize: { xs: "0.8rem", sm: "0.8rem" }}}>
                    At IzzyHR.com, we have a rich history of providing top-notch HRIS solutions to diverse industries. Our vision is to revolutionize the way businesses manage their HR processes, by offering innovative features such as timekeeping, payroll, and leave management. With our user-friendly interface and robust functionality, we empower organizations to streamline their HR operations and enhance employee satisfaction.
                </Typography>
            </Box>
        </Box>
    )
}

export default Index;