import React from 'react';
import Section1 from '../pricing/Section1'
import AboutUsBackground1 from "../../assets/images/AboutUsBackground1.png";
import NavBar from "../../components/NavBar";
import Box from "@mui/material/Box";
import Section2 from '../pricing/Section2';
import Section3 from '../pricing/Section3';
import Section4 from '../pricing/Section4';

function Index() {
    return (
        <>
            <Box
                component='main'
                id='main'
                sx={{
                    backgroundImage: `url(${AboutUsBackground1})`,
                    // minHeight: '30vh',
                    backgroundSize: 'fit',
                    // backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
            >
                <NavBar/>
                <Section1 />
            </Box>
            <Section2 />
            <Section3 />
            <Section4 />
        </>
    )
}

export default Index