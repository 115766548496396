import React from 'react';
import Box from "@mui/material/Box";
import AboutUsBackground1 from "../../assets/images/AboutUsBackground1.png";
import NavBar from "../../components/NavBar";
import Section1 from "../aboutus/Section1";
import Section2 from "../aboutus/Section2";
import Section3 from "../aboutus/Section3";
import Section4 from "../aboutus/Section4";
import Section5 from "../aboutus/Section5";

function Index() {
    return (
        <>
            <Box
                component='main'
                id='main'
                sx={{
                    backgroundImage: `url(${AboutUsBackground1})`,
                    // minHeight: '30vh',
                    backgroundSize: 'fit',
                    // backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
            >
                <NavBar/>
                <Section1 />
            </Box>
            <Section2 />
            <Section3 />
            <Section4 />
            <Section5 />

        </>
    )
}

export default Index;