import React from 'react';
import {BrowserRouter} from "react-router-dom";
import Box from '@mui/material/Box'
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import AppRoutes from "../Router"
import { ThemeProvider, createTheme } from '@mui/material/styles'
import Section1Background from '../assets/images/Section1Background.png';


const theme = createTheme({
    palette: {
        primary: {
            main: '#FF6B01',
        },
        secondary: {
            main: '#FFFFFF',
        },
    },
    typography: {
        fontFamily: 'Arial, sans-serif',
    },
});

function Index () {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <Box>
                    {/*<Box*/}
                    {/*    component='main'*/}
                    {/*    id='main'*/}
                    {/*    sx={{*/}
                    {/*        backgroundImage: `url(${Section1Background})`,*/}
                    {/*        minHeight: '110vh',*/}
                    {/*        backgroundSize: 'cover',*/}
                    {/*        backgroundPosition: 'center',*/}
                    {/*        backgroundRepeat: 'no-repeat',*/}
                    {/*    }}*/}
                    {/*    display='flex'*/}
                    {/*    flexDirection='column'*/}
                    {/*    justifyContent='space-between'*/}
                    {/*>*/}
                    {/*    <NavBar/>*/}
                        <AppRoutes />

                    {/*</Box>*/}
                    <Footer />
                </Box>
            </ThemeProvider>
        </BrowserRouter>
    )
}

export default Index;