import React from 'react';
import { Box, Typography, Link, IconButton  } from '@mui/material';
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

function Index() {
    return (
        <Box
            component="footer"
            sx={{
                background: 'linear-gradient(to right, #ff6b01, #fcb69f)',
                color: '#fff',
                padding: { xs: '20px 10px', sm: '20px 50px', md: '20px 100px' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: { xs: 'center', sm: 'flex-start' },
                }}
            >
                {/* Left Side - Logo and Links */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: { xs: 'center', sm: 'flex-start' },
                        gap: { xs: '10px', sm: '40px' },
                    }}
                >
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{
                            fontWeight: 'bold',
                            textAlign: { xs: 'center', sm: 'left' },
                        }}
                    >
                        izzyhr.com
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: { xs: '10px', sm: '20px' },
                            alignItems: { xs: 'center', sm: 'flex-start' },
                        }}
                    >
                        <Link href="#" underline="none" color="inherit">
                            About Us
                        </Link>
                        <Link href="#" underline="none" color="inherit">
                            Services
                        </Link>
                        <Link href="#" underline="none" color="inherit">
                            Contact Us
                        </Link>
                        <Link href="#" underline="none" color="inherit">
                            FAQ
                        </Link>
                        <Link href="#" underline="none" color="inherit">
                            Blog
                        </Link>
                    </Box>
                </Box>

                {/* Right Side - Social Media Icons */}
                <Box
                    sx={{
                        display: 'flex',
                        gap: '15px',
                        alignItems: 'center',
                        justifyContent: { xs: 'center', sm: 'flex-end' },
                        marginTop: { xs: '20px', sm: '0' },
                    }}
                >
                    <IconButton color="inherit" href="#" aria-label="Facebook">
                        <FacebookIcon />
                    </IconButton>
                    <IconButton color="inherit" href="#" aria-label="Twitter">
                        <TwitterIcon />
                    </IconButton>
                    <IconButton color="inherit" href="#" aria-label="Instagram">
                         <InstagramIcon />
                    </IconButton>
                    <IconButton color="inherit" href="#" aria-label="LinkedIn">
                         <LinkedInIcon />
                    </IconButton>
                    <IconButton color="inherit" href="#" aria-label="YouTube">
                         <YouTubeIcon />
                    </IconButton>
                </Box>
            </Box>

            {/* Bottom Links */}
            <Box
                sx={{
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: { xs: '10px', sm: '40px' },
                    fontSize: '14px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                <Typography variant="body2">
                    © 2024 izzyhr.com. All rights reserved.
                </Typography>
                <Link href="#" underline="none" color="inherit">
                    Privacy Policy
                </Link>
                <Link href="#" underline="none" color="inherit">
                    Terms and Conditions
                </Link>
                <Link href="#" underline="none" color="inherit">
                    Cookie Policy
                </Link>
            </Box>
        </Box>
    );
}

export default Index;
