import React from 'react';
import Section1 from '../home/Section1';
import Section2 from '../home/Section2';
import Section3 from '../home/Section3';
import Section4 from '../home/Section4';
import Section5 from '../home/Section5';
import Section6 from '../home/Section6';
import Section7 from '../home/Section7';
import Section8 from '../home/Section8';
import Box from '@mui/material/Box'
import Section1Background from '../../assets/images/Section1Background.png';
import NavBar from "../../components/NavBar";

function Index () {
    return (
        <>
            <Box
                component='main'
                id='main'
                sx={{
                    backgroundImage: `url(${Section1Background})`,
                    minHeight: '110vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
            >
                <NavBar/>
                <Section1 />
            </Box>

            <Section2 />
            <Section3 />
            <Section4 />
            <Section5 />
            <Section6 />
            <Section7 />
            <Section8 />
        </>
    )
}

export default Index;