import React from 'react';
import {Box, Typography, Container, Button} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function Index () {
    const [activeIndex, setActiveIndex] = React.useState(null);

    const toggleFAQ = (index: any) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqItems = [
        {
            question: "How much does it cost?",
            answer: "Our pricing varies depending on the size of your organization and the features you require. Please contact our sales team for a personalized quote.",
        },
        {
            question: "What are the payment terms?",
            answer: "We offer flexible payment terms, including monthly and annual billing options. Contact our sales team to discuss the best payment arrangement for your business.",
        },
        {
            question: "What is included in each plan?",
            answer: "Each plan includes essential HRIS features such as timekeeping, payroll, and leave management. Contact our sales team to learn more about the specific features included in each plan.",
        },
        {
            question: "Can I customize my plan?",
            answer: "Yes, we offer customizable plans to meet the unique needs of your organization. Contact our sales team to discuss your requirements and get a personalized plan.",
        },
        {
            question: "Is there a free trial?",
            answer: "Yes, we offer a free trial period for you to explore our HRIS platform and evaluate its suitability for your business. Contact our sales team to get started.",
        },
    ];

    return (
        <Container maxWidth="lg" sx={{ padding: "40px 20px" }}>
            <Box>
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: 2,
                        fontSize: { xs: "1rem", md: "1.8rem" },
                        textAlign: 'center'
                    }}
                >
                    Pricing FAQs
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: "center",
                        color: "#555",
                        marginBottom: "20px",
                        fontSize: { xs: "0.8rem", sm: "0.8rem" }
                    }}
                >
                    Find answers to common questions about our pricing, payment terms, and plan specifics.
                </Typography>
            </Box>

            <Box>
                {faqItems.map((item, index) => (
                    <div
                        key={index}
                        style={{
                            border: "1px solid #ddd",
                            borderRadius: "15px",
                            marginBottom: "10px",
                            overflow: "hidden",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <div
                            onClick={() => toggleFAQ(index)}
                            style={{
                                cursor: "pointer",
                                padding: "15px",
                                // backgroundColor: "#f9f9f9",
                                fontWeight: "bold",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <span>{item.question}</span>
                            <span>{activeIndex === index ? <ExpandLessIcon/> : <ExpandMoreIcon />}</span>
                        </div>
                        {activeIndex === index && (
                            // <div style={{ padding: "15px", backgroundColor: "#fff" }}>
                            //
                            // </div>
                            <Typography
                            variant="body1"
                            sx={{
                            textAlign: "left",
                            color: "#555",
                            marginBottom: "20px",
                            fontSize: { xs: "0.8rem", sm: "0.8rem" },
                            padding: "15px"
                        }}
                            >
                        {item.answer}
                            </Typography>
                        )}
                    </div>
                ))}
            </Box>
            <Box sx={{
                marginTop: '50px'
            }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: 2,
                        // fontSize: { xs: "1rem", md: "1.8rem" },
                        textAlign: 'center'
                    }}
                >
                    Still have questions?
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: "center",
                        color: "#555",
                        marginBottom: "20px",
                        fontSize: { xs: "0.8rem", sm: "0.8rem" }
                    }}
                >
                    Contact our support team for further assistance.
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "row", sm: "row" },
                        gap: 2,
                        justifyContent: { xs: "center", md: "center" },
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "#F28C33",
                            color: "#fff",
                            textTransform: "none",
                            borderRadius: "25px",
                            padding: "10px 30px",
                        }}
                    >
                        Contact
                    </Button>
                </Box>

            </Box>
        </Container>

    )
}

export default Index;