import React from "react";
import { Box, Container, Typography, Button, Grid } from '@mui/material';

function Index() {
    return(

        <Box
            sx={{
                padding: { xs: '20px', sm: '40px 60px', md: '50px 100px', lg: '50px 200px' }, // Responsive padding
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' }, // Stack items on small screens, align side-by-side on larger screens
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: { xs: 4, md: 0 }, // Add spacing between items on small screens

            }}
        >
            {/* Left Section */}
            <Box
                sx={{
                    borderRight: { xs: 'none', md: '2px solid #CECECE' }, // Remove border on small screens
                    paddingRight: { xs: 0, md: 10 }, // Adjust padding on small screens
                    textAlign: { xs: 'center', md: 'left' }, // Center text on small screens
                }}
            >
                <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    color="primary"
                    gutterBottom
                >
                    TRANSFORMING HR
                </Typography>
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: 2,
                        fontSize: { xs: "1rem", md: "1.8rem" }
                    }}
                >
                    Our Journey and Vision for the Future
                </Typography>
            </Box>

            {/* Right Section */}
            <Box
                sx={{
                    paddingLeft: { xs: 0, md: 15 }, // Remove padding on small screens
                    textAlign: { xs: 'center', md: 'left' }, // Center text on small screens
                }}
            >
                <Typography variant="body1" sx={{ marginBottom: { xs: 2, md: 4 } }}>
                    IzzyHR.com offers a comprehensive suite of features designed to streamline your HR processes. From timekeeping and payroll management to leave management and more, our platform is built to meet the diverse needs of industries. With IzzyHR.com, you can focus on what matters most - your people.
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "row", sm: "row" },
                        gap: 2,
                        justifyContent: { xs: "center", md: "flex-start" },
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "#F28C33",
                            color: "#fff",
                            textTransform: "none",
                            borderRadius: "25px",
                            padding: "10px 30px",
                        }}
                    >
                        Learn More
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "#393939",
                            color: "#fff",
                            textTransform: "none",
                            borderRadius: "25px",
                            padding: "10px 45px",
                        }}
                    >
                        Sign Up
                    </Button>
                </Box>
            </Box>

        </Box>
    )
}

export default Index;