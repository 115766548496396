import React from "react";
import { Box, Container, Typography, Grid } from "@mui/material";
import Laptop from "../../../assets/images/laptop.png";
import Section6Background from "../../../assets/images/Section6Background.png";
import Section6Background2 from "../../../assets/images/Section6Background2.png";

function Index() {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                marginBottom: {xs: '70px', md: '0px'},
                padding: { xs: "20px", md: "50px" }, // Add responsive padding
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={4} alignItems="center">
                    {/* Left Section: Image */}
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundImage: `url(${Section6Background})`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                            }}
                        >
                            <img
                                src={Section6Background2}
                                alt="Computer"
                                style={{
                                    width: "100%",
                                    maxWidth: "500px", // Adjust the max width for larger screens
                                    height: "auto", // Maintain aspect ratio
                                }}
                            />
                        </Box>
                    </Grid>

                    {/* Right Section: Content */}
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                textAlign: { xs: "center", md: "left" },
                                mx: { xs: "auto", md: 0 },
                            }}
                        >
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: "bold",
                                    color: "#000",
                                    fontSize: { xs: "1.4rem", md: "2rem" },
                                    marginBottom: 2,
                                }}
                            >
                                Simplify Employee Leave Requests and Approvals with Our Streamlined Feature
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: "#555",
                                    fontSize: { xs: "0.9rem", md: "1rem" },
                                    lineHeight: 1.6,
                                    marginBottom: 4,
                                }}
                            >
                                Our leave management feature makes it easy for employees to request
                                time off and for managers to approve or decline those requests,
                                streamlining the entire process.
                            </Typography>

                            {/* Highlights Section */}
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            fontWeight: "bold",
                                            color: "#F28C33",
                                            fontSize: { xs: "1.4rem", md: "2rem" },
                                            textAlign: { xs: "center", md: "left" },
                                        }}
                                    >
                                        50%
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "#555",
                                            fontSize: { xs: "0.9rem", md: "1rem" },
                                            lineHeight: 1.6,
                                            textAlign: { xs: "center", md: "left" },
                                        }}
                                    >
                                        Efficiently manage employee leave requests and approvals
                                        with our feature.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            fontWeight: "bold",
                                            color: "#F28C33",
                                            fontSize: { xs: "1.4rem", md: "2rem" },
                                            textAlign: { xs: "center", md: "left" },
                                        }}
                                    >
                                        50%
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "#555",
                                            fontSize: { xs: "0.9rem", md: "1rem" },
                                            lineHeight: 1.6,
                                            textAlign: { xs: "center", md: "left" },
                                        }}
                                    >
                                        Simplify leave management and improve productivity with
                                        our streamlined feature.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Index;

